<template>
  <div class="add-contactUser">
    <LoadingOverlay v-if="isFetchingFilter" />
    <div v-else>
      <h3 class="text-primary main-title m-1 pt-1">Add New Recruiters</h3>
      <ValidationObserver ref="addNewRecruiter" v-slot="{ handleSubmit }">
        <form id="addNewRecruiter" @submit.prevent="handleSubmit(onSubmit)">
          <CCard class="mt-2">
            <CCardBody>
              <CRow>
                <CCol lg="6">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Select the Team</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="team_id"
                          :value="user.team_id"
                          :options="
                            options && options['team_names']
                              ? options['team_names']
                              : []
                          "
                          :multiple="false"
                          :clearable="false"
                          @input="handleChangeSelect"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Team Level</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="team_level_id"
                          :value="user.team_level_id"
                          :options="
                            options && options['team_level']
                              ? options['team_level']
                              : []
                          "
                          :multiple="false"
                          :clearable="false"
                          @input="handleChangeSelect"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Recruitment Types</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="recruitment_type_id"
                          :value="user.recruitment_type_id"
                          :options="
                            options && options['recruitment_types']
                              ? options['recruitment_types']
                              : []
                          "
                          :multiple="false"
                          :clearable="false"
                          @input="handleChangeSelect"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="customer_user_type" class="mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >contact Types</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="contact_type_id"
                          :value="user.contact_type_id"
                          :options="
                            options && options['contact_type']
                              ? options['contact_type']
                              : []
                          "
                          :multiple="false"
                          :clearable="false"
                          @input="handleChangeSelect"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard class="mt-2">
            <CCardBody>
              <h5 class="pb-2 text-primary">User Details</h5>
              <CRow>
                <CCol lg="6">
                  <CRow name="first_name" class="mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >First Name</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="first_name"
                          :value="user.first_name"
                          :error="errors[0]"
                          @input="handleInput"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="last_name" class="mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >Last Name</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="last_name"
                          :value="user.last_name"
                          :error="errors[0]"
                          @input="handleInput"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow name="phone" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Phone</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        ref="phone"
                        :rules="{
                          required: true,
                          phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        }"
                        v-slot="{ errors }"
                      >
                        <PhoneInput
                          name="phone"
                          :value="user.phone"
                          :options="
                            options && options['dialingCode']
                              ? options['dialingCode']
                              : []
                          "
                          :error="errors[0]"
                          @change="handleInput"
                          ref="phoneNo"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="email" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Email</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          name="email"
                          :value="user.email"
                          :error="errors[0]"
                          @input="handleInput"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CRow name="password" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Password</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        name="password"
                        :rules="{
                          required: true,
                          password_length: 8,
                          password_strength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        }"
                        v-slot="{ errors }"
                      >
                        <PasswordInput
                          name="password"
                          :value="user.password"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                        <small class="hint">
                          Password format: 8 characters - containing upper &
                          lower case letters, numbers and a special character.
                          No words.
                        </small>
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow name="confirm_password" class="row mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >Confirm Password</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        rules="required|confirmed:password"
                        v-slot="{ errors }"
                      >
                        <PasswordInput
                          name="confirmPassword"
                          :value="confirmPassword"
                          @input="handleConfirmPassword"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <div class="text-center mx-0 mt-2 pt-4 action-area">
            <CButton
              variant="outline"
              size="lg"
              type="button"
              color="primary"
              shape="square"
              class="px-5 m-1 mt-3"
              @click="resetForm"
              >Clear All</CButton
            >
            <CButton
              type="submit"
              size="lg"
              color="primary"
              shape="square"
              class="px-5 m-1 mt-3"
              >Submit</CButton
            >
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import { extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
  },
  data() {
    return {
      user: {},
      payload: {},
      confirmPassword: null,
    };
  },
  computed: {
    ...mapGetters([
      "getTeamList",
      "getTeamLevel",
      "getRecruitmentTypes",
      "dialingCodeWithCountryCode",
      "contactType",
      "isFetchingFilter",
      "getCustomerId",
      "getOrgID",
    ]),
    options() {
      return {
        team_names: this.getTeamList || [],
        team_level: this.getTeamLevel || [],
        recruitment_types: this.getRecruitmentTypes || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        contact_type: this.contactType || [],
      };
    },
  },
  methods: {
    ...mapActions([
      "getTeamDetails",
      "getTeamLevels",
      "getRecruitmentType",
      "showToast",
      "fetchContactType",
      "createNewTeamMember",
      "initFetchContactInfoOptions",
    ]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.user, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      };
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    resetForm() {
      this.user = {};
      this.payload = {};
    },
    async onSubmit() {
      const isValid = await this.$refs.addNewRecruiter.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.createNewTeamMember(this.payload)
    },
  },
  mounted() {
    this.getTeamDetails({
      customer_id: this.getCustomerId,
      organisation_id: this.getOrgID,
    });
    this.getTeamLevels();
    this.getRecruitmentType();
    this.fetchContactType();
    this.initFetchContactInfoOptions();
  },
};
</script>
<style lang="scss" scoped>
.action-area {
  .btn {
    width: 172px;
  }
}
</style>
