var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-contactUser"},[(_vm.isFetchingFilter)?_c('LoadingOverlay'):_c('div',[_c('h3',{staticClass:"text-primary main-title m-1 pt-1"},[_vm._v("Add New Recruiters")]),_c('ValidationObserver',{ref:"addNewRecruiter",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"id":"addNewRecruiter"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CCard',{staticClass:"mt-2"},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_user_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Select the Team")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"team_id","value":_vm.user.team_id,"options":_vm.options && _vm.options['team_names']
                            ? _vm.options['team_names']
                            : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_user_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Team Level")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"team_level_id","value":_vm.user.team_level_id,"options":_vm.options && _vm.options['team_level']
                            ? _vm.options['team_level']
                            : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_user_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Recruitment Types")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"recruitment_type_id","value":_vm.user.recruitment_type_id,"options":_vm.options && _vm.options['recruitment_types']
                            ? _vm.options['recruitment_types']
                            : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_user_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("contact Types")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"contact_type_id","value":_vm.user.contact_type_id,"options":_vm.options && _vm.options['contact_type']
                            ? _vm.options['contact_type']
                            : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1)],1)],1)],1),_c('CCard',{staticClass:"mt-2"},[_c('CCardBody',[_c('h5',{staticClass:"pb-2 text-primary"},[_vm._v("User Details")]),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"first_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("First Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"first_name","value":_vm.user.first_name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"last_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Last Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"last_name","value":_vm.user.last_name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"phone"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Phone")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"phone",attrs:{"rules":{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PhoneInput',{ref:"phoneNo",attrs:{"name":"phone","value":_vm.user.phone,"options":_vm.options && _vm.options['dialingCode']
                            ? _vm.options['dialingCode']
                            : [],"error":errors[0]},on:{"change":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"email"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Email")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"email","value":_vm.user.email,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"password"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Password")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"name":"password","rules":{
                        required: true,
                        password_length: 8,
                        password_strength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PasswordInput',{attrs:{"name":"password","value":_vm.user.password,"error":errors[0]},on:{"input":_vm.handleInput}}),_c('small',{staticClass:"hint"},[_vm._v(" Password format: 8 characters - containing upper & lower case letters, numbers and a special character. No words. ")])]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3",attrs:{"name":"confirm_password"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Confirm Password")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PasswordInput',{attrs:{"name":"confirmPassword","value":_vm.confirmPassword,"error":errors[0]},on:{"input":_vm.handleConfirmPassword}})]}}],null,true)})],1)])],1)],1)],1)],1),_c('div',{staticClass:"text-center mx-0 mt-2 pt-4 action-area"},[_c('CButton',{staticClass:"px-5 m-1 mt-3",attrs:{"variant":"outline","size":"lg","type":"button","color":"primary","shape":"square"},on:{"click":_vm.resetForm}},[_vm._v("Clear All")]),_c('CButton',{staticClass:"px-5 m-1 mt-3",attrs:{"type":"submit","size":"lg","color":"primary","shape":"square"}},[_vm._v("Submit")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }